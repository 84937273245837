<template>
  <div class="iframe_page" v-loading="loading">
    <CustomPageEdit :json="json" @submit="handleSubmit" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import request from '@/utils/request'
import { tryJSONParse } from '@/utils/utils'
import { ElMessage } from 'element-plus'
import CustomPageEdit from '@/components/CustomPageEdit/index.vue'

/**
 * options:
 * activeCode
 */

const router = useRouter()
const route = useRoute()
const activeCode = route.query?.activeCode || ''

const json = ref(null)
const loading = ref(false)

onMounted(() => {
  initInfo()
})

function initInfo() {
  getJson()
}
function getJson() {
  loading.value = true
  request({
    url: '/web/system/active/getActiveInfo',
    data: { activeCode },
  })
    .then((data) => {
      const jsonObj = tryJSONParse(data?.activeConfig)
      console.log('🚀 ~ jsonObj:', jsonObj)
      json.value = jsonObj
    })
    .finally(() => (loading.value = false))
}

function handleSubmit(json) {
  loading.value = true
  request({
    url: '/web/system/active/updateActive',
    data: {
      activeCode,
      activeConfig: JSON.stringify(json || ''),
    },
  })
    .finally(() => (loading.value = false))
    .then(() => {
      ElMessage.success('编辑成功')
      router.back()
    })
}
</script>

<style lang="less" scoped>
.iframe_page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
</style>
